.form-control {
    width: 500px;
}

.video-arrow-wrapper {
    cursor: pointer;
}

#links {
    text-align: center;
}

.film_bg {
    background-image: url("../img/bgs/bg_film.jpg");
    background-attachment: fixed;
    background-size: cover;
}

#contact-form {
    margin-top: 1em;
}

#email_addr {
    width: 100%;
    border: 1px solid #000;
}

#msg {
    width: 100%;
    margin-top: 1em;
}

#newsletter-radiobutton-div {
    margin: 0.9em 0 1em 0;
}

#getintouch {
    border: 0px;
    background-color: #000;
    color: #fff;
    width: 100%;
    height: 3em;
    margin-top: 0.8em;
    font-size: 1.2em;
}

#submit-response {
    color: red;
    margin-top: 10px;
}

@media (min-width: 48em) {
    #email_addr,
    #msg,
    #getintouch {
        width: 50%;
    }
}

/////////////////////////////////////////////////////////////////////////////////////////

html {
    font-size: 16px;
    height: 100%;
}

body {
    height: 100%;
    font-size: 1rem;
    font-family: 'Titillium Web', sans-serif;
    padding-top: 50px;
}

#baseArea {
    display: none;
    z-index: 1;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

@media (min-width: 40.0625em) {
    #baseArea {
        display: block;
    }
}

#content {
    position: relative;
    z-index: 2;
}

@media (min-width: 87.5em) {
    .container {
        width: 1340px;
    }
}

.index-container {
    padding: 0;
}

.artist-container,
.workshop-container {
    max-width: 920px;
    padding: 0;
}

.thumbnail {
    position: relative;
    border-radius: 0px;
    border-color: #333;
    border-width: 0 0 1px 0;
    padding: 0;
    margin-bottom: 0;
    background-color: white;
}

.thumbnail.no-divider {
    border-width: 0 0 0 0;
}

.thumbnail p {
    margin: 10px 0 0 0;
}

.thumbnail p:first-child {
    margin: 0;
}

.thumbnail .caption {
    padding: 15px 15px;
}

.thumbnail .caption.lessoncap {
    padding: 5px 15px;
}

.lessonThumbs {
    padding: 0 15px;
}

.thumbnail a .channelImage {
    padding: 8px 20px 5px 20px;
}

.workshop-container {
    background-color: #fff;
}

.workshop-without-grid .video-arrow-wrapper,
.workshop-with-grid .video-arrow-wrapper {
    margin: 10px;
    text-align: center;
}

#workshop-details {
    padding: 0;
}

#workshop-details .caption {
    padding: 0 20px 20px 20px;
}

#indexGrid2 .thumbnail:nth-child(2),
#indexGrid2 .thumbnail:nth-child(4),
#indexGrid2 .thumbnail:nth-child(6) {
    border-left: 1px solid #333;
}

@media (min-width: 40.0625em) {
    .thumbnail {
        padding: 5px;
        border-width: 0px;
    }
}

#mlt,
#showMe,
#artSearchResults,
#workshopsSearchResults,
#lessonSearchResults {
    margin-top: 20px;
}

#mlt-heading,
#artSearch-heading,
#workshopsSearch-heading,
#lessonSearch-heading {
    text-align: center;
    background-color: #ccc;
    padding: 10px;
    font-weight: bold;
}

.search-results,
#mlt {
    margin-bottom: 40px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

h1 {
    font-size: 1.4rem;
    font-weight: bold;
}

h2 {
    margin-top: 5px;
    font-size: 1.3rem;
}

h3 {
    font-size: 1.2rem;
}

h4 {
    font-size: 1.1rem;
}

h5 {
    font-size: 1.2rem;
    margin-top: 10px;
}

.artsAwardGrid1 h2 {
    color: #333;
}

.navbar {
    a {
        font-weight: normal;
    }
}

a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    p {
        font-weight: normal;
    }
    h2 {
        font-weight: bold;
    }
}

a:focus,
a:hover {
    text-decoration: none;
    color: #8f55bb;
    h2 {
        color: #8f55bb;
    }
}

.thumbButton,
.vidswap-box,
#audioOFF,
#audioON,
#audio-credit {
    cursor: pointer;
}

.all_bg,
.default_bg {
    background-image: url(../img/bgs/bg_default.jpg);
    background-attachment: fixed;
    background-size: cover;
}

.art_bg {
    background-image: url(../img/bgs/bg_art.jpg);
    background-attachment: fixed;
    background-size: cover;
}

.book_bg {
    background-image: url(../img/bgs/bg_book.jpg);
    background-attachment: fixed;
}

.museums_bg,
.what_is_bg {
    background-image: url(../img/bgs/bg_museums.jpg);
    background-attachment: fixed;
}

.stage_bg {
    background-image: url(../img/bgs/bg_stage.jpg);
    background-attachment: fixed;
    background-size: cover;
}

.workshops_bg {
    background-image: url("../img/bgs/bg_workshops.jpg");
    background-attachment: fixed;
    background-size: cover;
}

.lessons_bg {
    background-image: url("../img/bgs/bg_lessons.jpg");
    background-attachment: fixed;
    background-size: cover;
}

.artsaward_bg {
    background-image: url("../img/bgs/bg_artsaward.jpg");
    background-attachment: fixed;
    background-size: cover;
}

.reviews_bg,
.art-review_bg,
.book-review_bg,
.museum-review_bg,
.stage-review_bg {
    background-image: url(../img/bgs/bg_reviews.jpg);
    background-attachment: fixed;
    background-size: cover;
}

.btn-social > :first-child {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.btn-twitter {
    background-color: #693275;
}

.btn-twitter:hover {
    background-color: #51304c;
}

form {
    margin: 0;
}

/* filter box */
$select-color: #333;
.filterArea {
    /* wrapper style */
    position: relative;
    display: block;
    max-width: 500px;
    min-width: 180px;
    margin: 0 auto;
    height: 38px;
    select {
        /* Select Reset */
        border: none;
        outline: none;
        background-color: rgba(255, 255, 255, 0.4);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0;
        margin: 0;
        display: block;
        width: 100%;
        cursor: pointer;
        color: $select-color   ;
        /* custom select appearance */
        padding: 6px 55px 6px 6px;
        option {
            color: $select-color;
            background-color: rgba(255, 255, 255, 0.4);
        }
    }
    /* the down arrow */
    &:after {
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        height: 100%;
        line-height: 41px;
        content: '\25BC';
        text-align: center;
        z-index: -1;
        background-color: #4c0099;
        color: #fff;
        font-size: 18px;
    }
}

/* search */
#search-box {
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: 500px;
}

#search-form {
    height: 40px;
    border: none;
    background-color: rgba(255, 255, 255, 0.4);
    overflow: hidden;
}

#search-text {
    border-width: 0;
    background: transparent;
}

#search-box input[type="text"] {
    width: 90%;
    padding: 9px 0 12px 1em;
    color: #333;
    outline: none;
}

#search-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    width: 80px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    border-width: 0;
    background-color: #8000ff;
    cursor: pointer;
}

/* footer */

footer {
    text-align: center;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    a {
        color: #fff;
        font-weight: normal;
    }
    div,
    img {
        padding: 10px;
    }
    .btn {
        margin-top: 10px;
    }
}

@media(min-width:40.0625em) {
    footer {
        margin-top: 3em;
    }
}

#showcase-title,
#audioOFF,
#audioON,
#audio-credit {
    position: absolute;
}

.item {
    -moz-box-shadow: 4px 4px 3px #999;
    -webkit-box-shadow: 4px 4px 3px #999;
    box-shadow: 4px 4px 3px #999;
}

.caption-image {
    position: absolute;
    border: 10px #fff solid;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.8);
}

.carousel-caption {
    display: none;
    right: 5%;
    left: 5%;
}

.carousel-caption h4 {
    display: none;
}

#showcase-title {
    display: none;
}

.carousel-indicators {
    display: none;
}

#audio-credit {
    bottom: 5px;
    right: 7px;
    display: none;
}

.carousel-control {
    display: none;
}

@media (min-width:48em) {
    .carousel-caption {
        display: block;
        bottom: -10px;
    }
    #audio-credit {
        display: block;
    }
    .carousel-control {
        display: block;
    }
}

@media (min-width:61.9375em) {
    #showcase-title {
        display: block;
    }
    .caption-image {
        transform: translate(-50%, -56%) scale(0.9);
    }
    .carousel-caption {
        bottom: 15px;
    }
    .carousel-caption h4 {
        display: block;
    }
    .carousel-indicators {
        display: block;
    }
}

.captionText h4,
.captionText h3 {
    line-height: 0.6;
    margin-top: 13px;
}

.audioOFF {
    visibility: hidden;
}

#audioOFF,
#audioON {
    top: 7px;
    right: 12px;
}

#showcase-title {
    top: 5px;
    left: 5px;
}

.carousel-indicators {
    bottom: 0px;
}

.cssGrid {
    display: grid;
}

.artsAwardGrid1 {
    grid-template-columns: 1fr;
}

@media (min-width: 40.0625em) {
    .artsAwardGrid1 {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.artsAwardGrid2 {
    grid-template-columns: 1fr;
}

@media (min-width: 40.0625em) {
    .artsAwardGrid2 {
        grid-template-columns: 450px auto;
    }
}

.artsAwardGrid3 {
    grid-template-columns: 1fr;
}

@media (min-width: 40.0625em) {
    .artsAwardGrid3 {
        grid-template-columns: 350px auto;
    }
}

.lesson-grid {
    grid-template-columns: 1fr;
}

@media (min-width: 35.88406em) {
    .lesson-grid {
        grid-template-columns: 1fr 1fr;
    }
}

.workshop-with-grid {
    grid-template-columns: 1fr;
}

@media (min-width: 26.25em) {
    .workshop-with-grid {
        grid-template-columns: 220px auto;
    }
}

.workshop-without-grid {
    grid-template-columns: 1fr;
}

@media (min-width: 26.25em) {
    .workshop-without-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

#mltGrid1 {
    background-color: white;
    grid-template-columns: 1fr;
}

@media (min-width: 20em) {
    #mltGrid1 {
        grid-gap: 20px;
        grid-template-columns: repeat(2, 1fr);
    }
    #mltGrid1 .mltGrid1Image {
        grid-column: 1 / 3;
    }
}

@media (min-width: 40.0625em) {
    #mltGrid1 {
        grid-template-columns: repeat(4, 1fr);
    }
}

#artistGrid1 {
    background-color: white;
    grid-template-columns: 1fr;
}

@media (min-width: 20em) {
    #artistGrid1 {
        grid-template-columns: repeat(2, 1fr);
    }
    #artistGrid1 .artistGrid1Image {
        grid-column: 1 / 3;
    }
    #artistGrid1 .artistGrid1Description {
        grid-column: 1 / 3;
    }
}

@media (min-width: 40.0625em) {
    #artistGrid1 {
        grid-template-columns: repeat(3, 1fr);
    }
    #artistGrid1 .artistGrid1Image {
        grid-column: 1 / 4;
    }
    #artistGrid1 .artistGrid1Description {
        grid-column: 1 / 4;
    }
}

@media (min-width: 62em) {
    #artistGrid1 {
        grid-template-columns: repeat(4, 1fr);
    }
    #artistGrid1 .artistGrid1Image {
        grid-column: 1 / 5;
    }
    #artistGrid1 .artistGrid1Description {
        grid-column: 1 / 3;
    }
}

#indexGrid1 {
    grid-template-columns: 1fr;
}


#indexGrid2 {
    grid-template-columns: 1fr 1fr;
}

@media (min-width: 40.0625em) {
    #indexGrid1 {
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }
    #indexGrid2 {
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }
}

@media (min-width: 62em) {
    #indexGrid1 {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 25px;
    }
    #indexGrid2 {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 25px;
    }
}

@media (min-width: 1200px) {
    #indexGrid1 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 30px;
    }
    #indexGrid2 {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 30px;
    }
}

.headImageIndex {
    background-image: url("../img/headers/index/header1.jpg");
}

.headImageall {
    background-image: url("../img/headers/all.jpg");
}

.headImageart {
    background-image: url("../img/headers/art.jpg");
}

.headImagebook {
    background-image: url("../img/headers/books.jpg");
}

.headImagestage {
    background-image: url("../img/headers/stage.jpg");
}

.headImagemuseums {
    background-image: url("../img/headers/museums.jpg");
}

.headImagereviews {
    background-image: url("../img/headers/reviews.jpg");
}

.headImage-activities {
    background-image: url("../img/headers/activities.jpg");
}

.headImagelessons {
    background-image: url("../img/headers/lessons.jpg");
}

.headImagewhat_is {
    background-image: url("../img/headers/whatis.jpg");
}

.headImagearts_award {
    background-image: url("../img/headers/artsaward.jpg");
}

.headImage-workshop {
    background-image: url("../img/headers/workshops.jpg");
}

@media(min-width: 40.0625em) {
    .headImageall,
    .headImagelessons,
    .headImagearts_award,
    .headImage-workshop,
    .headImage-activities {
        margin-bottom: 3em;
    }
}

#headImage {
    position: relative;
    height: 250px;
    background-color: black;
    transition: background 2s linear;
    background-size: cover;
    background-position: 50% 50%;
    overflow: hidden;
}

#headImage #largeLogo {
    text-align: center;
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-51%, -50%);
    -ms-transform: translate(-51%, -50%);
    transform: translate(-51%, -50%);
}

#headImage #largeLogo .colourLogoLetters {
    font-family: 'Quattrocento Sans', 'sans-serif';
    font-size: 3.8rem;
    font-weight: 700;
    letter-spacing: -8px;
    line-height: 3.6rem;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff, 2px 2px 4px rgba(255, 255, 255, 0.5), -2px -2px 4px rgba(255, 255, 255, 0.5), -2px 2px 4px rgba(255, 255, 255, 0.5), 2px -2px 4px rgba(255, 255, 255, 0.5);
}

#headImage #largeLogo .colourLogoLetters #firstBlock {
    display: inline;
}

#headImage #largeLogo .colourLogoLetters #secondBlock {
    display: block;
}

#headImage #largeLogo #strapLine {
    font-family: 'Shadows Into Light Two', cursive;
    font-size: 1.4rem;
    color: #fff;
}

#headImageFooter {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 10px;
}

@media (min-width: 26.25em) {
    #headImage {
        border-bottom-width: 0;
    }
    #headImage #largeLogo .colourLogoLetters {
        font-size: 4.4rem;
        letter-spacing: -6px;
    }
    #headImage #largeLogo .colourLogoLetters #secondBlock {
        display: inline;
    }
    #headImage #largeLogo #strapLine {
        font-size: 2.0rem;
    }
}

@media (min-width: 40.0625em) {
    #headImage {
        height: 300px;
    }
    #headImage #largeLogo .colourLogoLetters {
        font-size: 5rem;
        letter-spacing: -7px;
        text-shadow: -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff, 2px 2px 0 #fff, 4px 4px 5px rgba(255, 255, 255, 0.5), -4px -4px 5px rgba(255, 255, 255, 0.5), -4px 4px 5px rgba(255, 255, 255, 0.5), 4px -4px 5px rgba(255, 255, 255, 0.5);
    }
    #headImage #largeLogo #strapLine {
        font-size: 2.8rem;
    }
    #headImageFooter {
        margin-bottom: 3em;
    }
}

@media (min-width: 48em) {
    #headImage #largeLogo .colourLogoLetters {
        font-size: 6.2rem;
        letter-spacing: -10px;
        line-height: 4.2rem;
    }
    #headImage #largeLogo #strapLine {
        font-size: 3.0rem;
    }
}

@media (min-width: 75em) {
    #headImage #largeLogo .colourLogoLetters {
        font-size: 7rem;
        letter-spacing: -12px;
        line-height: 4.6rem;
    }
    #headImage #largeLogo #strapLine {
        font-size: 3.4rem;
    }
}

.colourLogoLetters #firstBlock span:nth-child(1),
.colourLogoLetters #thirdBlock span:nth-child(1) {
    color: #43b4ba;
}

.colourLogoLetters #firstBlock span:nth-child(2),
.colourLogoLetters #thirdBlock span:nth-child(2) {
    color: #e2c41c;
}

.colourLogoLetters #firstBlock span:nth-child(3),
.colourLogoLetters #thirdBlock span:nth-child(3) {
    color: #b51736;
}

.colourLogoLetters #firstBlock span:nth-child(4),
.colourLogoLetters #thirdBlock span:nth-child(4) {
    color: #693275;
}

.colourLogoLetters #firstBlock span:nth-child(5),
.colourLogoLetters #thirdBlock span:nth-child(5) {
    color: #51304c;
}

.colourLogoLetters #firstBlock span:nth-child(6),
.colourLogoLetters #thirdBlock span:nth-child(6) {
    color: #aa1a70;
}

.colourLogoLetters #firstBlock span:nth-child(7),
.colourLogoLetters #thirdBlock span:nth-child(7) {
    color: #87b945;
}

.colourLogoLetters #firstBlock span:nth-child(8),
.colourLogoLetters #thirdBlock span:nth-child(8) {
    color: #e2751c;
}

.colourLogoLetters #firstBlock span:nth-child(9),
.colourLogoLetters #thirdBlock span:nth-child(9) {
    color: #e3c41e;
}

.colourLogoLetters #firstBlock span:nth-child(10),
.colourLogoLetters #thirdBlock span:nth-child(10) {
    color: #b91777;
}

.colourLogoLetters #secondBlock span:nth-child(1),
.colourLogoLetters #fourthBlock span:nth-child(1) {
    color: #e2751c;
}

.colourLogoLetters #secondBlock span:nth-child(2),
.colourLogoLetters #fourthBlock span:nth-child(2) {
    color: #e3c41e;
}

.colourLogoLetters #secondBlock span:nth-child(3),
.colourLogoLetters #fourthBlock span:nth-child(3) {
    color: #b91777;
}

.colourLogoLetters #secondBlock span:nth-child(4),
.colourLogoLetters #fourthBlock span:nth-child(4) {
    color: #43b4ba;
}

.colourLogoLetters #secondBlock span:nth-child(5),
.colourLogoLetters #fourthBlock span:nth-child(5) {
    color: #693275;
}

.colourLogoLetters #secondBlock span:nth-child(6),
.colourLogoLetters #fourthBlock span:nth-child(6) {
    color: #e2751c;
}

.navbar {
    background-color: #fff;
    border-width: 0;
}

.navbar .navbar-toggle {
    border-color: #43b4ba;
}

.navbar .navbar-toggle .icon-bar {
    background-color: #43b4ba;
}

.navbar .navbar-toggle:hover,
.navbar .navbar-toggle:focus {
    background-color: #e2751c;
    border-color: #fff;
}

.navbar .navbar-toggle:hover .icon-bar,
.navbar .navbar-toggle:focus .icon-bar {
    background-color: #fff;
}

.navbar .navbar-brand {
    margin-left: 0;
    padding: 16px 0 0 10px;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: -4px;
    font-family: 'Quattrocento Sans', 'sans-serif';
}

.navbar .navbar-brand #firstBlock,
.navbar .navbar-brand #secondBlock {
    display: none;
}

.navbar .navbar-brand #thirdBlock,
.navbar .navbar-brand #fourthBlock {
    display: inline;
}

@media (min-width: 48em) {
    .navbar {
        font-size: 1.2em;
    }
    .navbar .navbar-brand #firstBlock,
    .navbar .navbar-brand #secondBlock {
        display: inline;
    }
    .navbar .navbar-brand #thirdBlock,
    .navbar .navbar-brand #fourthBlock {
        display: none;
    }
}

.video-arrow-wrapper {
    position: relative;
}

.video-arrow-wrapper #video-arrow-button {
    position: absolute;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    cursor: pointer !important;
    border: .3em solid #fff;
    opacity: .85;
    -webkit-box-shadow: 4px 4px 8px #000;
    -moz-box-shadow: 4px 4px 8px #000;
    box-shadow: 4px 4px 8px #000;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    background: #454545;
    background: -moz-linear-gradient(top, #454545 0, #232323 50%, #161616 50%, #3f3f3f 100%);
    background: -webkit-gradient(linear, 0% 0, 0% 100%, color-stop(0%, #454545), color-stop(50%, #232323), color-stop(50%, #161616), color-stop(100%, #3f3f3f));
    background: -webkit-linear-gradient(top, #454545 0, #232323 50%, #161616 50%, #3f3f3f 100%);
}

.video-arrow-wrapper #video-arrow-button:hover {
    -webkit-box-shadow: 0 0 80px #fff;
    -moz-box-shadow: 0 0 80px #fff;
    box-shadow: 0 0 80px #fff;
}

.video-arrow-wrapper #video-arrow-button #video-arrow {
    margin-left: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 18px solid white;
}

.carousel-fade .carousel-inner .item {
    opacity: 0;
    transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
    opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
    left: 0;
    opacity: 0;
    z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
    opacity: 1;
}

.carousel-fade .carousel-control {
    z-index: 2;
}

/*
WHAT IS NEW IN 3.3: "Added transforms to improve carousel performance in modern browsers."
now override the 3.3 new styles for modern browsers & apply opacity
*/

@media all and (transform-3d),
(-webkit-transform-3d) {
    .carousel-fade .carousel-inner > .item.next,
    .carousel-fade .carousel-inner > .item.active.right {
        opacity: 0;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.prev,
    .carousel-fade .carousel-inner > .item.active.left {
        opacity: 0;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.next.left,
    .carousel-fade .carousel-inner > .item.prev.right,
    .carousel-fade .carousel-inner > .item.active {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.carousel-fade {
    display: none;
    margin: 3em 0;
}

@media (min-width: 48em) {
    .carousel-fade {
        display: block;
    }
}

.modal-footer {
    text-align: left;
}

.artistGrid1Image,
.workshopGridImage {
    position: relative;
}

.artistGrid1Image #imageHeader,
.workshopGridImage #imageHeader {
    position: absolute;
    top: 12px;
    left: 15px;
}

.artistGrid1Thumb img {
    width: 100%;
}

@media (min-width: 62em) {
    #artistGrid1 {
        div:nth-child(3),
        div:nth-child(4) {
            margin-top: 20px;
        }
    }
}

#artistTitle {
    font-size: 1.6rem;
    font-weight: bold;
}

#artist-description {
    padding: 20px;
    font-size: 1.1rem;
}

#lesson-details {
    padding: 20px;
}

.typeIcon {
    position: absolute;
    top: 5px;
    right: 19px;
    width: auto;
    height: auto;
}

#app-wrap {
    text-align: center;
}

/*# sourceMappingURL=../css/cs.map */